import { useRef, useState } from "react"
import { LabelField, SpanField } from "../styles/Form.styles"
import { MSubTitle } from "../styles/Text.styles"
import { TextInfo } from "../styles/Text.styles"
import { Spacer } from "../styles/Containers.styles"
import { MFormBtn } from "../styles/Buttons.style"
import emailjs from 'emailjs-com'
import apikey from "../../apikey"
import { useTranslation } from "react-i18next"

const ReservationForm = ({title}) => {

    const form = useRef()

    const { t } = useTranslation()

    const now = new Date();
    const defaultDate = new Date(now).toISOString().split("T")[0];
    const hour = now.getHours();
    const minute = now.getMinutes()
    const defaultTime = (hour < 10 ? "0" + hour.toString() : hour) + ":" +
    (minute < 10 ? "0" + minute.toString() : minute)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [people, setPeople] = useState('')
    const [date, setDate] = useState(defaultDate)
    const [time, setTime] = useState(defaultTime)
    const [reserved, setReserved] = useState(false)
    const [error, setError] = useState(false)

    const reserve = async (e) => {
        
        e.preventDefault();
        setError(false)
        setReserved(false)

        if( name === '' || email === '' || contact === '' || people === '' || date === '' || time === '' ){
            return setError(true)  
        }

        let variables = {
            name,
            email,
            contact,
            people,
            date,
            time
        }

        emailjs.send(apikey.SERVICE,apikey.RESERVATION_TEMPLATE , variables, apikey.USER_ID)
        .then(result => {
        setReserved(true)
        },
        error => {
        alert( 'Había un error, por favor LLÁMANOS a +34 933 19 99 99 para reservar.<br /> An error occured, please call at +34 933 19 99 99 ',error.text)
        })
        e.target.reset();
    }

    return (
    <> 
        <div style={{width:'80vw'}}>
            {!reserved && <div>
                    {title && <div><MSubTitle>{t('form.reservation')}</MSubTitle>
                    </div>}
                    <Spacer space='4vh'/>
                    <form>
                            <div style={{display:'flex', flexDirection:'column', gap:'5vh', position:'relative'}}>
                        
                                    <LabelField>
                                        <input type="text" name="name" placeholder={t('form.name')} value={name} onChange={(e)=> setName(e.target.value)} />
                                        {/* <SpanField>Name</SpanField> */}
                                    </LabelField>

                                    <LabelField >
                                        <input type="email" name="email" placeholder={t('form.email')} value={email} onChange={(e)=> setEmail(e.target.value)}/>
                                        {/* <SpanField>Email</SpanField> */}
                                    </LabelField>

                                    <LabelField>
                                        <input type="text" name="contact" placeholder={t('form.mobile')} value={contact} onChange={(e)=> setContact(e.target.value)}/>
                                    </LabelField>
                    
                                    <LabelField>
                                        <input type="number" name="people" placeholder={t('form.people')} value={people} onChange={(e)=> setPeople(e.target.value)} />
                                        {/* <SpanField>People</SpanField> */}
                                    </LabelField>

                                    <LabelField>
                                        <input type="time" name="time" placeholder='Time' value={time} onChange={(e)=> setTime(e.target.value)}/>
                                        {/* <SpanField style={{marginLeft:'2vw'}}>Time</SpanField> */}
                                    </LabelField>

                                    <LabelField>
                                        <input type="date" name="date" placeholder='DD-MM-YYYY' value={date} onChange={(e)=> setDate(e.target.value)} />
                                        {/* <SpanField>Date</SpanField> */}
                                    </LabelField>
                

                                <div style={{display:'flex', gap:'1vh', alignItems:'center'}}>
                                    
                                    <MFormBtn onClick={reserve} type='button' bgColor='black' hBgColor='white' color='white' hColor='black'>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <TextInfo>{t('form.reserve')}</TextInfo>
                                        </div>
                                    </MFormBtn>
                                    {reserved && <div><TextInfo>{t('form.msg')}</TextInfo></div>}
                                    {error && <div><TextInfo>{t('form.error')}</TextInfo></div>}
                                </div>
                            </div>
                            
                    </form>
            </div>}
            {reserved && <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div>
                                <span style={{fontSize:'3vh'}}>{t('form.msg')}</span>
                                <Spacer space='2vh' />
                                <hr />
                                <Spacer space='2vh' />
                                <MFormBtn onClick={() => setReserved(false)} type='button' bgColor='black' hBgColor='white' color='white' hColor='black'>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <span style={{fontSize:'2vh'}}>{t('form.new')}</span>
                                    </div>
                                </MFormBtn>
                                <Spacer space='2vh' />
                                <div style={{height:'40vh', overflow:'hidden'}}>
                                    <img src={window.location.origin + "/media/details/7.jpg"} width='auto' height='100%' />
                                </div>                        
                            </div>
                      </div>}
        </div>
    </>
    )
}

export default ReservationForm
