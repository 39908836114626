const cat = {
    
    menu: {
        menu:"CARTA",
        contact: "CONTACTE",
        petra: "PETRA",
        gift: "REGAL"
    },
    home: {
        title:"Tradició catalana amb un toc pintoresc",
        mtitle:"Tradició <br />catalana amb un toc pintoresc",
        subTitle:"Cuina de mercat en un espai amb aires modernistes.",
        Btn :"RESERVAR TAULA",
        reserve:"SI VOL RESERVAR TAULA PER AVUI, SI US PLAU, TRUCA'NS"
    },
    slider:{
        menu:"Carta",
        details:"Detalls",
        see:"VEURE MÉS",
        complete:"VEURE MENÚ COMPLET",
    },
    form :{
        title:"Reservar",
        name: "Nom",
        email: "Correu Electrònic",
        mobile:"Telèfon",
        people:"Persones",
        reserve:"RESERVA",
        reservation:"Reservació",
        msg:"Ens posarem en contacte amb vostè aviat!",
        error:"Tots els camps són obligatoris!",
        new:"Fes una altra reserva"
    },
    about: {
        sub:"Petra és un restaurant de Barcelona ubicat al bonic barri del Born, un acollidor restaurant amb un estil molt propi.",
        desc:"Confrontant a la impressionant Basílica de Santa Maria del Mar, lloc que va propiciar la inspiració d'autèntics èxits literaris com La Catedral del Mar d'Ildefonso Falcones. Si ja coneixes el barri del Born, també anomenat el barri de la Ribera, podràs situar-nos fàcilment des de la façana de la Basílica de Santa Maria del Mar.",
        more:"SABER-NE MÉS",
        msg:"No volíem presentar-te el nostre restaurant sense més ni més, ja que la nostra principal comesa és la de servir sopars, dinars i menús de grups.",
        wait:"T'esperem! Sam & Joan",
        petra:{
            title:"PETRA",
            text1:"El Petra és un restaurant de Barcelona ubicat al bonic barri del Born, un acollidor restaurant amb un estil molt propi. Colindant a la impressionant Basílica de Santa Maria del Mar, lloc que va propiciar la inspiració d'autèntics èxits literaris com La Catedral del Mar d'Ildefonso Falcones.",
            text2:"El Petra és un restaurant en un lloc amb encant, en un carrer on anys enrere el gremi de barreters de Barcelona comerciaven i convivien en aquest autèntic carrer del barri de la Rivera, un lloc amb l'aroma de la Barcelona de principis del segle XV, un barri de pescadors a tan sols 200 metres del mar. El Petra és un restaurant on podràs descobrir petites coses com les seves artesanes vidrieres emplomades que conjunten fins cristalls de colors, les simpàtiques làmpades, les parets plenes de rajoles, les cadires de fusta, que de tant en tant cruixen amb encant, els miralls, els mobles com els de la iaia, els nostres lavabos amb un marcat estil modernista."
        },

        born:{
            title:"EL BORN",
            text1:"Si ja coneixes el barri del Born, també anomenat el barri de la Ribera, podràs situar-nos fàcilment des de la façana de la Basílica de Santa Maria del Mar. Mirant-la de front el nostre restaurant et quedarà a la teva esquerra, Et situes?",
            text2:"Estem en aquest carrer estret flanquejat per l'alta i imponent paret de pedra de la basílica de Santa Maria del Mar, pura pedra, pedra que li va donar nom al restaurant, «Petra» del llatí «pedra». Aquest nom que va ser gargotejat a mà alçada per Ada Parellada, la mestressa del Semproniana (www.semproniana.net) que es va fer càrrec del restaurant des de l'any 2000 fins al 2012, encara que la història del Petra va començar molt abans, per allà l'any 1975 quan el seu nom era «El Carpanta», avui som Sam i Joan els que portem el restaurant. Ens trobaràs al carrer Sombrerers número 13, a la cantonada amb el carrer Banys Vells (Baños Viejos), estem més o menys a la meitat del temple, just a sota de les campanes que repiquen amb nom propi, L'Andrea, Maria Salvatella, La Conventual (Quaresma) i L'Assumpta."
        },

        arrive:{
            title:"COM ARRIBAR",
            text1:"Si coneixes una mica el Born et serà molt fàcil de localitzar-nos, des del Passeig del Born pots anar fins a la façana de l'església de Santa Maria del Mar, el nostre carrer, mirant la façana et quedarà a la teva esquerra.",
            text2:"Si vens del centre són 10 minuts a peu, ja sigui des de la catedral o de la Plaça Catalunya o Plaça Sant Jaume el millor serà arribar a Via Laietana i anar baixant fins al carrer de l'Argenteria, aquest carrer us portarà fins a la mateixa façana de Santa Maria del Mar. Et mostrem alguns dels transports públics que et deixen a prop, ah! i recorda reservar."
        }

    },
    card:{
        title:"Per Menjar",
        card:"CARTA",
        zapping:"MENÚ ZAPPING",
        midday:"MENÚ DE MIGDIA",
        wines:"VINS"
    },
    contact:{
        contact:"Contacte"
    },
    cookie:{
        perfect:"¡Perfecte!",
        msg:"Aquest web utilitza cookies per millorar lexperiència de l’usuari."
    }
}



export default cat