import {useRef, useEffect} from 'react'
import { MenuBurger } from '../styles/Buttons.style';
import { useParams, Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';
import { Spacer } from '../styles/Containers.styles';
import Languages from './Languages';
import { MenuTextM } from '../styles/Text.styles';

const MenuMobile = ({open}) => {

  const linkRef = useRef(null);
   
  useEffect(() => {
     if (linkRef.current) {
      //   linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      linkRef.current.scrollIntoView(0,0);
     }
  }, []);

  const { t, i18n } = useTranslation();

  let {gen} = useParams();

    return (
    <MenuBurger open={open}>
          
            <Link ref={linkRef} to='/'><MenuTextM>HOME</MenuTextM></Link>
            <Link ref={linkRef} to='/menu'><MenuTextM>{t('menu.menu')}</MenuTextM></Link>
            <Link ref={linkRef} to='/petra'><MenuTextM>{t('menu.petra')}</MenuTextM></Link>
            {/* <Link to='/gift'>{t('menu.gift')}</Link> */}
            <Link ref={linkRef} to='/contact'><MenuTextM>{t('menu.contact')}</MenuTextM></Link>
            <Spacer space='20vh' />
            <div style={{paddingLeft:'2vh'}}>
              <Languages color='black' sColor='white' s/>
            </div>
         
  </MenuBurger>
    )
}

export default MenuMobile











