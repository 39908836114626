import MainMenu from "../components/MainMenu"
import ReservationForm from "../components/ReservationForm"
import { ReserveSection } from "../components/ReserveSection"
import { Title } from "../components/styles/Text.styles"
import { Spacer } from "../components/styles/Containers.styles"
import AnimatedPages from "../components/Animations/AnimatedPages"
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"

const Contact = () => {
    
    const {t} = useTranslation()

    return (
        <AnimatedPages>
            <div style={{display:'flex', flexDirection:'column'}}>
                <div>
                    <MainMenu color="black" selected='#98D1D9' selectedD='black' />
                </div>
                <Spacer space='10vh'/>
                <ReserveSection title={t('contact.contact')} />
                <Spacer space='10vh'/>
                <div style={{height:'50vh', padding:'0 10vh 10vh'}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5480026388964!2d2.1795946147619993!3d41.38390400417514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2fef651dacd%3A0xb1b07ad4d0029760!2sRestaurant%20Petra!5e0!3m2!1sen!2ses!4v1651224666584!5m2!1sen!2ses" width="100%" height="100%"  style={{border:'0'}}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                {/* <Footer /> */}
            </div>
        </AnimatedPages>
    )
}

export default Contact
