import {useEffect, Fragment} from 'react'
import { SubTitle, TextInfoS } from './styles/Text.styles'
import { Link } from 'react-router-dom'
import { Spacer,AboutBox, AboutBoxLeft, AboutContentBox, AboutBoxRight } from './styles/Containers.styles'
import { Trans, useTranslation } from 'react-i18next'
import Aos from 'aos'
import 'aos/dist/aos.css'

const AboutSmall = ({know}) => {

    const { t } = useTranslation()

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <AboutBox data-aos="fade-up">
            <AboutBoxLeft>
                <div>
                    <SubTitle style={{ lineHeight:'1.1', fontWeight:'500', fontFamily:'WS_Medium'}}>
                    <Trans >{t('about.sub')}</Trans>
                    </SubTitle>
                </div>
                <AboutContentBox>
                   {know &&  <span style={{marginTop:'auto', fontSize:'2vh',width:'40%'}}><Link to="/petra">{t('about.more')}</Link></span>}
                   <TextInfoS style={{width:'60%', right:'0'}}>
                        {t('about.desc')}
                    </TextInfoS>
                    {/* <span style={{width:'60%', fontSize:'1vw', right:'0'}}>
                        {t('about.desc')}
                    </span> */}
                </AboutContentBox>
            </AboutBoxLeft>
            <AboutBoxRight>
                <img src={window.location.origin + '/media/details/4.jpg'} width='100%' height='100%' />
            </AboutBoxRight>
        </AboutBox>
    )
}

export default AboutSmall
