import {useEffect} from 'react'
import { FormBox } from './styles/Form.styles'
import { Title, SubTitle, TextInfo } from './styles/Text.styles'
import { Spacer, ReservationBox } from './styles/Containers.styles'
import ReservationForm from './ReservationForm'
import { useTranslation } from 'react-i18next'
import Aos from 'aos'
import 'aos/dist/aos.css'

export const ReserveSection = ({title}) => {

    const  { t } = useTranslation()

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <ReservationBox data-aos="fade-up">
            <FormBox style={{padding:'0 0'}}>
                <Title style={{fontSize:'10vh', marginLeft:'-1vh'}}>{title || t('form.title')}</Title>
                <Spacer space='3vh' />
                <TextInfo>Carrer del Sombrerers, 13 <br/> 08003 Barcelona- El Born</TextInfo>

                <Spacer space='15vh' />
                <SubTitle> +34 933 19 99 99 <br/> INFO@RESTAURANTPETRA.COM</SubTitle>

                <div style={{position:'relative', top:'10vh', display:'flex', gap:'2vw'}}>
                    <TextInfo>{t('home.reserve')}</TextInfo>
                </div>
            </FormBox>
            {/* { title && <SubTitle>Reservar</SubTitle>} */}
            <ReservationForm title={title} />
        </ReservationBox>

    )
}
