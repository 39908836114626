import {useRef, useEffect, useState} from 'react'
import Burger from './Burger';
import MenuMobile from './MainMenu';

const Hamburger = ({color}) => {

    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false)
    const node = useRef();

    useEffect(() => {
        if(color =='white'){ 
            setShow(false)
        } else {
            setShow(true)
        }
    },color)

    return (
        <div ref={node} style={{position:'relative', zIndex:'20',display:'flex',justifyContent:'flex-end', paddingRight:'5vw'}}>
           {/* { show ? <img src={window.location.origin + '/media/icons/petraWB.png'} height='30vh' width='auto' style={{marginTop:'0.5vh'}}/> :
            <img src={window.location.origin + '/media/icons/PetraW.png'} height='30vh' width='auto' style={{marginTop:'0.5vh'}}/>} */}
            <Burger color={color} open={open} setOpen={setOpen} />
            <MenuMobile open={open} setOpen={setOpen} />
        </div>
    )
}

export default Hamburger
