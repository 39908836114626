import {useRef, useEffect} from 'react'
import { MSplitter } from '../styles/Containers.styles'
import { NavLink } from 'react-router-dom'

const LogoLine = () => {

    const linkRef = useRef(null);
   
    useEffect(() => {
       if (linkRef.current) {
        //   linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        linkRef.current.scrollIntoView(0,0);
       }
    }, []);

    return (
        <div style={{position:'absolute',padding:'1vh', width:'90vw'}}>
            <NavLink ref={linkRef} to='/'><img src={window.location.origin + '/media/icons/PetraWB.png'} height='30vh' width='auto' style={{marginTop:'0.5vh', marginLeft:'3vw'}}/></NavLink>
            <MSplitter color='black' bgColor='black' style={{marginLeft:'3vw'}}/>
        </div>
    )
}

export default LogoLine
