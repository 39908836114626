const en = {
    
    menu: {
        "menu":"MENU",
        "contact":"CONTACT",
        "petra": "PETRA",
        "gift": "GIFT" 
    },
    home: {
        "title":"Catalan tradition with an artistic touch",
        "subTitle":"Traditional catalan and seasonal dishes in an artistic restaurant with eye-catching tiles and windows.",
        "Btn" :"RESERVE TABLE",
        reserve:"IF YOU WANT TO RESERVE TABLE FOR TODAY, PLEASE, CALL US!"
    },
    slider:{
        menu:"Menu",
        details: "Details",
        see:"SEE MORE",
        complete:"SEE COMPLETE MENU",
    },
    form :{
        title:'Reserve',
        name: "Name",
        email: "Email",
        mobile:"Mobile",
        people:"People",
        reserve:"RESERVE",
        reservation:"Reservation",
        msg: "We will be getting in touch with you soon!",
        error:"All the fields are required!",
        new:"Make another reservation"
    },
    about: {
        sub:"Petra is a restaurant in Barcelona located in the beautiful Born district, a cozy place with its very own style",
        desc:"It is adjacent to the impressive Basilica of Santa Maria del Mar, a place that inspired authentic literary successes such as La Catedral del Mar by Ildefonso Falcones. If you already know the Born neighbourhood, also known as the Ribera neighbourhood, you can easily locate us from the facade of the Basilica of Santa Maria del Mar.",
        more:"KNOW MORE",
        msg:"We didn't want to just introduce you to our restaurant, our main task is to serve dinners, lunches and group menus, but we are much more than that!",
        wait:"We are waiting for you! Sam & Joan",
        petra:{
            title:"PETRA",
            text1:"Petra is a Barcelona restaurant located in the beautiful Born district, a cozy restaurant with a very own style. Adjacent to the impressive Basilica of Santa Maria del Mar, the place that inspired authentic literary successes such as the Cathedral of the Sea of Ildefonso Falcones.",
            text2:"The Petra is a restaurant in a charming place, in a street where years ago the guild of Barcelona's hat makers traded and lived together in this authentic 'carrero del Barri de la Rivera', a place with the scent of Barcelona from the beginning of the 15th century. a fishermen's quarter only 200 meters from the sea. The Petra is a restaurant where you can discover small things like its artisan stained glass windows that bring together fine colored crystals, the friendly lamps, the walls full of baldosines, the wooden chairs, which from time to time creak with charm, the mirrors, the furniture like those of the yaya, our toilets have a marked modernist style."
        },
        born:{
            title:"THE BORN",
            text1:"If you already know the Born district, also called the Ribera district, you can easily find us from the facade of the Basilica of Santa Maria del Mar. Looking at it from the front our restaurant will be on your left, do you find yourself?",
            text2:"We are in that narrow street flanked by the high and imposing stone wall of the basilica of Santa Maria del Mar, pure stone, stone that gave its name to the restaurant, 'Petra' from the Latin 'stone'. This name was scribbled by hand by Ada Parellada, the mestressa of the Semproniana (www.semproniana.net) who took charge of the restaurant from 2000 to 2012, although the history of Petra began long before, back in 1975 when its name was 'El Carpanta', today we are Sam and Joan who run the restaurant. You will find us in Sombrerers street number 13, in the corner with Banys Vells street (Baños Viejos), we are more or less in the middle of the temple, just below the bells that ring with their own name, L'Andrea, La Maria Salvatella, La Conventual (Quaresma) and L'Assumpta."
        },
        arrive:{
            title:"HOW TO ARRIVE",
            text1:"If you know a little the Born it will be very easy to locate us, from the 'Passeig del Born' you can go to the facade of the church of Santa Maria del Mar, our street, looking at the facade will be on your left.",
            text2:"If you come from the center it is a 10 minute walk, either from the cathedral or from Plaza Catalunya or Plaza Sant Jaume, it is best to get to Via Laietana and go down to Carrer de l'Argenteria, this street will take you to the facade of Santa Maria del Mar. We show you some of the public transports that leave you nearby, oh! and remember to book."
        }
    },
    card:{
        title:"Our Food",
        card:"MENU",
        zapping:"ZAPPING MENU",
        midday:"MID DAY MENU",
        wines:"WINES"
    },
    contact:{
        contact:"Contact"
    },
    cookie:{
        perfect:"Perfect!",
        msg:"This website uses cookies to enhance the user experience."
    }

}

export default en