import Hamburger from "../components/Mob/Hamburger"
import LogoLine from "../components/Mob/LogoLine"
import { Spacer } from "../components/styles/Containers.styles"
import { ReserveSection } from "../components/Mob/ReserveSection"
import AnimatedPages from "../components/Animations/AnimatedPages"
import { useTranslation } from "react-i18next"
import Footer from "../components/Mob/Footer"

const Contact = () => {
    
    const {t} = useTranslation()

    return (
        <AnimatedPages>
            <div style={{position:'fixed', top:'0', right:'2%', zIndex:'5', padding:'1vh', width:'24vw'}}>
                <Hamburger color='black' />
            </div>
            <LogoLine />
            <Spacer space='8vh'/>
        
             <ReserveSection title={t('contact.contact')} />
             <Spacer space='8vh'/>
            <div style={{height:'50vh'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5480026388964!2d2.1795946147619993!3d41.38390400417514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2fef651dacd%3A0xb1b07ad4d0029760!2sRestaurant%20Petra!5e0!3m2!1sen!2ses!4v1651224666584!5m2!1sen!2ses" width="100%" height="100%"  style={{border:'0'}}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" ></iframe>
            </div>
                <Footer />
        </AnimatedPages>
    )
}

export default Contact
