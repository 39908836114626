import { MTitle } from "../styles/Text.styles";
import { Swiper, SwiperSlide} from "swiper/react"
import { FreeMode, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useTranslation } from "react-i18next";
import { MSliderBox } from "../styles/Containers.styles";




const RestaurantSlider = () => {


    const {t} = useTranslation()
    // const Box = ({src}) => {

    //     return (<div style={{height:'40vh', width:'60vw'}}>
    //         <img src={src}  width='100%' height='auto' />
    //     </div>)
    // }

    
    return (
        <div style={{height:'50vh', padding:'2vh'}} >
            <MTitle>{t('slider.details')}</MTitle>
            <Swiper
            modules={[ Navigation, FreeMode, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1.25}
            freeMode={true}
            // pagination={{ clickable: false }}
            scrollbar={{ draggable: true }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            style={{marginLeft:'-4.5vh'}}
            >
    
            <SwiperSlide><MSliderBox src={window.location.origin + '/media/details/1.jpg'} /></SwiperSlide>
            <SwiperSlide><MSliderBox src={window.location.origin + '/media/details/2.jpg'} /></SwiperSlide>
            <SwiperSlide><MSliderBox src={window.location.origin + '/media/details/3.jpg'} /></SwiperSlide>
            <SwiperSlide><MSliderBox src={window.location.origin + '/media/details/4.jpg'} /></SwiperSlide>

            </Swiper>
        </div>
    )
}

export default RestaurantSlider
