import {useState} from 'react'
import MainMenu from '../components/MainMenu'
import HomePageBanner from '../components/HomePageBanner'
import MenuSlider from '../components/MenuSlider'
import RestaurantSlider from '../components/RestaurantSlider'
import AboutSmall from '../components/AboutSmall'
import { ReserveSection } from '../components/ReserveSection'
import { Splitter } from '../components/styles/Containers.styles'
import Footer from '../components/Footer'
import PopUp from '../components/PopUp'
import { useEffect } from 'react'
import AnimatedPages from '../components/Animations/AnimatedPages'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Spacer } from '../components/styles/Containers.styles'
import SelfSlider from '../components/SelfSlider'


const Home = () => {

    const [ show, setShow ]  = useState(false)

  
    const onClick = () => {
        setShow(false)
    }
        
    useEffect(() => {
     
        
        if ( sessionStorage.getItem("PopUp") == "true") {
            console.log("Hello")
        } else {
            setTimeout(() => {
                setShow(true)
                sessionStorage.setItem("PopUp", "true")
            }, 2000 )
        }
    },[])


    return (
        <AnimatedPages>
           { show && <PopUp onClick={onClick} animate={true}/>}
            <MainMenu hamburger='white' />
            <HomePageBanner />
            <MenuSlider />
            <Spacer space='25vh' />
            <Splitter color='black' bgColor='black' id='reserve'/>
            <Spacer space='5vh' />
            <ReserveSection />
            <Splitter width='85vw' color='black' bgColor='black' />
            <Spacer space='5vh' />
            <AboutSmall know={true} />
            <Spacer space='5vh' />
            <Splitter color='black' bgColor='black' />
            <RestaurantSlider />
            <Spacer space='20vh' />
            <Footer />
           
        </AnimatedPages>
    )
}

export default Home
