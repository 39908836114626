import styled from "styled-components";
import { device } from './Device';

// Check for the Font Sizes in this section

// Check line 1087.. in main css

export const FormPetra = styled.form`
  display:flex; 
  flex-direction:column; 
  gap:12vh;

  @media ${device.mobileS} {
    gap:6vh;
    }

    @media ${device.mobileM} {
      gap:6vh;
    }

    @media ${device.mobileL} {
      padding-right:3vw;
      gap:6vh;
    }

    @media ${device.tablet} {
      padding-right:0vw;
      gap:12vh;
    }

    @media ${device.laptop} {
     
      gap:12vh; 
    } 
`

export const FormBox = styled.div`
  width:50%; 
  padding:1vh 0; 
  position:relative;

  @media ${device.mobileS} {
      width:100%; 
    }

    @media ${device.mobileM} {
      width:100%; 
    }

    @media ${device.mobileL} {
      width:100%; 
    }

    @media ${device.tablet} {
      width:80%; 
    }

    @media ${device.laptop} {
      width:50%; 
    }  

`

export const FormRow = styled.div`
  display:flex; 

  @media ${device.mobileS} {
      gap:2vw;
    }

    @media ${device.mobileM} {
      gap:2vw;
    }

    @media ${device.mobileL} {
      gap:2vw;
    }

    @media ${device.tablet} {
      gap:3vw;
    }

    @media ${device.laptop} {
      gap:5vw;
    }  


`

export const LabelField = styled.label`
  position: relative;
  display: block;
  width: 100%;
  cursor: text;

  input, textarea {
    display: block;
    width: 100%;
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
    padding: 0 0 14px;
    font-size: clamp(1rem, 1vw + 0.2rem, 1.8rem);
    line-height: 1;
    color: #000000;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #000000;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
    }
    :-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
    }

    :focus{
        outline:none !important
    }
  }

  textarea {
      resize: none;
  }

  
`

export const SpanField = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 15px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;

`