import { useState, useEffect } from 'react'
import MainMenu from '../components/MainMenu'
import { Spacer, PetraContent, AccordionBox, AccordianItem, AccordionItemHeader,AccordianHeaderBox,  AccordianItemTitle, AccordianItemShort, AccordianItemText, AccordianRow, AccordianLeft, AccordianRight } from '../components/styles/Containers.styles'
import AboutSmall from '../components/AboutSmall'
import { SubTitle, TextInfo } from '../components/styles/Text.styles'
import AnimatedPages from '../components/Animations/AnimatedPages'
import Footer from '../components/Footer'
import { Splitter } from '../components/styles/Containers.styles'
import { useTranslation } from "react-i18next"
import RestaurantSlider from '../components/RestaurantSlider'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Petra = () => {

    const [selected, setSelected] = useState(null)

    const {t} = useTranslation()

    const toggle = (i) => {
        console.log(i)
        if (selected === i){
            return setSelected(null)
            
        }
        setSelected(i)
    }

    const data = [
        {
            title:t('about.petra.title'),
            src:"/media/details/3.jpg",
            text1:t('about.petra.text1'),
            src2:"/media/details/2.jpg",
            text2:t('about.petra.text2')
        },
        {
            title:t('about.born.title'),
            src:"/media/general/outside.jpg",
            text1:t('about.born.text1'),
            src2:"/media/details/26.jpg",
            text2:t('about.born.text2')
        },
        {
            title:t('about.arrive.title'),
            src:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5480026388964!2d2.1795946147619993!3d41.38390400417514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2fef651dacd%3A0xb1b07ad4d0029760!2sRestaurant%20Petra!5e0!3m2!1sen!2ses!4v1651224666584!5m2!1sen!2ses" width="100%" height="100%"  style={{border:'0'}}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>,
            text1:t('about.arrive.text1'),
            src2:"/media/details/7.jpg",
            text2:t('about.arrive.text2')
        },
    ]

    useEffect(() => {
        Aos.init({duration: 1500, once:true})
    },[])

    return (
        <AnimatedPages>
            <div>
                <MainMenu color="black" selected='#98D1D9' selectedD='black' />
                <Spacer space='18vh' />

                <AboutSmall know={false}/>
                <Spacer space='10vh' />
                <AccordionBox>
                    <SubTitle style={{fontSize:'5vh', lineHeight:'1.1', fontWeight:'500', fontFamily:'WS_Medium'}}>
                        Sobre Nosotros
                    </SubTitle>
                    {/* <TrackVisibility once={true} partialVisibility={false}>
                        <Accordian data={data} />
                    </TrackVisibility > */}
                    <Spacer space='10vh' />
                    {data.map((item, i)=> (
                    
                    
                        //   <div className={selected === i ? "acc-item-show-"+(item.color): 'acc-item hover-'+(item.color)}>
                        <AccordianItem key={i} data-aos="fade-up" >
                                        <Splitter color='black' bgColor='black' />
                            
                                <AccordionItemHeader onClick={() => toggle(i)} >
                                    
                                            <AccordianHeaderBox>
                                                <AccordianItemTitle>{item.title}</AccordianItemTitle>
                                                <AccordianItemShort>{item.text1}</AccordianItemShort>
                                            </AccordianHeaderBox>
                                    
                                            <div style={{margin:'auto 0'}}>
                                            { selected === i ?  <img src={window.location.origin + '/media/icons/minus.png'}  width='20vh' height='1vh'/> : <img src={window.location.origin + '/media/icons/plus.png'} height='20vh' width='auto' />}
                                            </div>
                                </AccordionItemHeader>     
                                <Spacer space='2vh' />
                                <div className={selected === i ? 'acc-text show': 'acc-text'} onClick={() => toggle(i)}>
                                    <AccordianRow>
                                        { i < 2 && <AccordianLeft>
                                            
                                                <img src={window.location.origin + item.src}  width='100%' height='auto' />
                                            
                                        </AccordianLeft> }
                                        { i == 2 && <AccordianLeft>
                                            <div style={{height:'100%', MarginRight:'10vh'}}>{item.src}</div>
                                        </AccordianLeft> }
                                        <AccordianRight>
                                            <img src={window.location.origin + item.src2} height='auto' width='40%' className='image-2' />
                                                <AccordianItemText>
                                                    {item.text2}
                                                </AccordianItemText>
                                        </AccordianRight>
                                    </AccordianRow>
                                </div>
                        </AccordianItem>
                    
                    ))}

                </AccordionBox>
                <Splitter color='black' bgColor='black' />
                
                <PetraContent>
                    <div className='content'>
                        <SubTitle style={{fontSize:'5vh', lineHeight:'1.1', fontWeight:'500', fontFamily:'WS_Medium'}}>
                           {t('about.msg')}
                            <Spacer space='5vh' />
                           {t('about.wait')}
                        </SubTitle>
                    </div>
                </PetraContent>

                {/* <Splitter color='black' bgColor='black' />
                <RestaurantSlider /> */}
                <Footer />     
            </div>
        </AnimatedPages>
    )
}

export default Petra
