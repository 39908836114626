import {useState} from 'react'
import { MItemCard, MItemCardPic, ItemCardContent } from '../styles/Containers.styles'

const MenuItem = ({src, title, description}) => {

    return (
        <MItemCard>
            <div style={{overflow:'hidden'}}>
                <MItemCardPic src={src} />
                    {/* <img src={src} height='100%' width='100%' alt={title} />
                </ItemCardPic> */}
            </div>
            {/* {<ItemCardContent>
                <div>{title}</div>
                <div>{description}</div>
            </ItemCardContent>} */}
        </MItemCard>

    )
}

export default MenuItem
