import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const MenuTitle = styled.span`
    font-size: clamp(1rem, 1.8vw + 0.2rem, 1.8rem);
    color:${props => props.color || "white"};
`

export const MenuText = styled.span`
    font-size: clamp(0.5rem, 1vw + 0.2rem, 1.8rem);
    color:${props => props.color || "white"};
    font-family: WS_Medium;
`

export const MenuTextLink = styled(NavLink)`
   font-size: clamp(1rem, 1vw + 0.2rem, 1.8rem);
    color:${props => props.color || "white"};
    font-family: WS_Medium;

    :hover{
        font-weight: bold;
    }

    &.active {
        color:${props => props.selected || props.selectedD};
    }
`



export const Title = styled.span`
    font-size: clamp(2.5rem, 5vw + 0.2rem, 4rem);
    font-weight: 500;
    font-family: WS_Medium;
`

export const SubTitle = styled.span`
    font-size: clamp(1rem, 2.3vw + 0.2rem, 4rem);
    font-weight: 400;
`


export const TextInfo = styled.span`
    font-size: clamp(0.8rem, 1vw + 0.2rem, 1.8rem);
    /* font-size:16px; */
`

export const TextInfoS = styled.span`
    font-size: clamp(0.5rem, 1.2vh + 0.2rem, 1.8rem);
    /* font-size:16px; */
`




// Mobile
export const MTitle = styled.span`
    font-size: 4vh;
    font-weight: 500;
    font-family: WS_Medium;
`
export const MSubTitle = styled.span`
    font-size: 2.5vh;
    font-weight: 500;
`

export const MTextInfo = styled.span`
    font-size: clamp(1rem, 1.2vw + 0.2rem, 1.8rem);
`

export const MenuTextM = styled.span`
letter-spacing: 0.3vh;
`