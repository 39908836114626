import { useRef, useState, useEffect } from "react"
import { SubTitle } from "./styles/Text.styles"
import { FormPetra, FormBox, FormRow, LabelField, SpanField } from "./styles/Form.styles"
import { Spacer } from "./styles/Containers.styles"
import { FormBtn } from "./styles/Buttons.style"
import { TextInfo } from "./styles/Text.styles"
import emailjs from 'emailjs-com'
import apikey from "../apikey"
import { useTranslation } from "react-i18next"

const ReservationForm = ({title}) => {

    const form = useRef()

    const { t } = useTranslation()

    const now = new Date();
    const defaultDate = new Date(now).toISOString().split("T")[0];
    const hour = now.getHours();
    const minute = now.getMinutes()
    const defaultTime = (hour < 10 ? "0" + hour.toString() : hour) + ":" +
    (minute < 10 ? "0" + minute.toString() : minute)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [people, setPeople] = useState('')
    const [date, setDate] = useState(defaultDate)
    const [time, setTime] = useState(defaultTime)
    const [reserved, setReserved] = useState(false)
    const [error, setError] = useState(false)
    
    const reserve = async (e) => {

        e.preventDefault();
        setError(false)
        setReserved(false)
 
        if( name === '' || email === '' || contact === '' || people === '' || date === '' || time === '' ){
            return setError(true)  
        }

        let variables = {
            name,
            email,
            contact,
            people,
            date,
            time
        }

        emailjs.send(apikey.SERVICE,apikey.RESERVATION_TEMPLATE , variables, apikey.USER_ID)
        .then(result => {
        setReserved(true)
        setName('')
        setEmail('')
        setContact('')
        setPeople('')
        setDate(defaultDate)
        setTime(defaultTime)
        },
        error => {
            console.log(error)
        alert( 'Había un error, por favor LLÁMANOS a +34 933 19 99 99 para reservar.<br /> An error occured, please call at +34 933 19 99 99 ',error.text)
        })
        e.target.reset();
    }

    return (

        <FormBox>
                {!reserved && <div>
                    {title && <div><SubTitle>{t('form.reservation')}</SubTitle>
                    <Spacer space='2vh'/>
                    </div>}
                    <FormPetra>
                       
                            <FormRow>
                                <LabelField>
                                    <input type="text" name="name" placeholder={t('form.name')} value={name} onChange={(e)=> setName(e.target.value)} />
                                    {/* <SpanField>Name</SpanField> */}
                                </LabelField>

                                <LabelField>
                                    <input type="email" name="email" placeholder={t('form.email')} value={email} onChange={(e)=> setEmail(e.target.value)}/>
                                    {/* <SpanField>Email</SpanField> */}
                                </LabelField>

                                <LabelField>
                                    <input type="text" name="contact" placeholder={t('form.mobile')} value={contact} onChange={(e)=> setContact(e.target.value)}/>
                                    {/* <SpanField>Email</SpanField> */}
                                </LabelField>
                            </FormRow>

                            <FormRow>
                                <LabelField>
                                    <input type="number" name="people" placeholder={t('form.people')} value={people} onChange={(e)=> setPeople(e.target.value)} />
                                    {/* <SpanField>People</SpanField> */}
                                </LabelField>

                                <LabelField>
                                    <input type="time" name="time" placeholder='Time' value={time} onChange={(e)=> setTime(e.target.value)}/>
                                    {/* <SpanField style={{marginLeft:'2vw'}}>Time</SpanField> */}
                                </LabelField>

                                <LabelField>
                                    <input type="date" name="date" value={date} onChange={(e)=> setDate(e.target.value)} />
                                    {/* <SpanField>Date</SpanField> */}
                                </LabelField>
                            </FormRow>
                            <Spacer space='2vh' />
                            
                            <div style={{ display:'flex', gap:'2vh', alignItems:'center', justifyContent:'right', marginTop:title ? '-4vh' : '2vh'}}>
                                {/* {reserved && <div><TextInfo>{t('form.msg')}</TextInfo></div>} */}
                                {error && <div><TextInfo>{t('form.error')}</TextInfo></div>}
                                <FormBtn onClick={reserve} type='button' bgColor='black' hBgColor='white' color='white' hColor='black'>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <span style={{fontSize:'2vh'}}>{t('form.reserve')}</span>
                                    </div>
                                </FormBtn>
                            </div>
           
                        
                    </FormPetra>
                </div>}
                  {reserved && <div style={{margin:'5%', marginLeft:'30%', height:'70%', width:'70%', border:'0.2vh solid black',display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div style={{padding:'4vh'}}>
                                
                                <span style={{fontSize:'3vh'}}>{t('form.msg')}</span>
                                <Spacer space='2vh' />
                                <hr />
                                <Spacer space='2vh' />
                                <FormBtn onClick={() => setReserved(false)} type='button' bgColor='black' hBgColor='white' color='white' hColor='black'>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <span style={{fontSize:'2vh'}}>{t('form.new')}</span>
                                    </div>
                                </FormBtn>
                        
                            </div>
                      </div>}
            
        </FormBox>
    )
}

export default ReservationForm
