import React from 'react'
import { useTranslation } from 'react-i18next';
import { ButtonLT } from "./styles/Buttons.style"


const Languages = ({color, sColor}) => {


    const lngs = {
        cat: { nativeName: 'CAT' },
        es: { nativeName: 'ES' },
        en: { nativeName: 'EN'},
        // fr: { nativeName: 'FR' }
      };
      
    const { t, i18n } = useTranslation();

    return (


        <div style={{display:'flex', height:'max-content'}}>

                {Object.keys(lngs).map((lng) => (
                    <div key={lng}>
                    <ButtonLT key={lng} color={color} style={{ color : i18n.resolvedLanguage === lng && sColor  }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                        {lngs[lng].nativeName} 
                    </ButtonLT>
                    {/* {lng !== 'cat' && '-' } */}
                    </div>
                
                ))}

        </div>

       
                
       
    )
}

export default Languages

