import React from 'react'
import { PopBoxM } from '../styles/Containers.styles'
import { Spacer } from '../styles/Containers.styles'

const PopUp = ({onClick}) => {

    const horarios = [
        {
            day:"Lunes",
            time:"13:00-16:00 / 19:30-22:30 "
        },
        {
            day:"Martes",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Miércoles",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Jueves",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Viernes",
            time:"13:00-16:00 / 19:30-23:00"
        },
        {
            day:"Sábado",
            time:"13:00-16:00 / 19:30-23:00"
        },
        {
            day:"Domingo",
            time:"13:00-16:00"
        },


    ]

    return (
        <div style={{height:'100vh', width:'100vw', backgroundColor:'rgba(152,209,217,0.7)', position:'fixed', zIndex:'300', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} onClick={onClick}>
            <PopBoxM>
                 {/* Cross for closing */}
                 {/* <div style={{height:'100%', width:'50%'}}>
                        <div style={{position:'absolute', zIndex:'1', fontSize:'2vh', right:'1.5vh', top:'10.5vh' }} onClick={onClick} >X</div>
                </div> */}
                <div style={{ height:'80%', width:'100%', padding:'2vh', display:'flex', flexDirection:'column', backgroundColor:'white'}}>
                    
                    <div style={{fontWeight:'bold', fontSize:'2.5vh', position:'relative'}}>
                        Restaurant Petra El Born
                        <img src='./media/icons/cross.png' height='60%' width='auto' style={{position:'absolute', top:'-2%',right:'-2%'}}/>
                    </div>
           
                    <Spacer space='5vh'/>
                    
                    <div>
                        <span style={{fontWeight:'bold', fontSize:'2.5vh'}}>Horarios Actuales</span>
                        {
                            horarios.map((item,i) => {
                                return( 
                                <div key={i}>
                                    <span style={{fontSize:'2vh'}}>{item.day}</span>
                                    <br />
                                    <span style={{fontSize:'2vh', opacity:'0.5'}}>{item.time}</span>
                                </div>)
                            })
                        }
                    </div>
                    <Spacer space='5vh'/>
                    
                    <div style={{fontWeight:'bold', fontSize:'2.5vh'}}>
                        OS ESPERAMOS!!<br />
                        RESERVAS ABIERTAS EN LA WEB
                    </div>
                </div>
                {/* <div style={{height:'40%'}}>
                    <img src='./media/details/6.jpg' height='100%' width='100%' />

                </div> */}

            </PopBoxM>
            
        </div>
    )
}

export default PopUp
