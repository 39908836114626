import React from 'react'
import { PopBox } from './styles/Containers.styles'
import { Spacer } from './styles/Containers.styles'

const PopUp = ({onClick, animate}) => {

    const horarios = [
        {
            day:"Lunes",
            time:"13:00-16:00 / 19:30-22:30 "
        },
        {
            day:"Martes",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Miércoles",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Jueves",
            time:"13:00-16:00 / 19:30-22:30"
        },
        {
            day:"Viernes",
            time:"13:00-16:00 / 19:30-23:00"
        },
        {
            day:"Sábado",
            time:"13:00-16:00 / 19:30-23:00"
        },
        {
            day:"Domingo",
            time:"13:00-16:00"
        },

    ]

    return (
        <div style={{height:'100vh', width:'100vw', backgroundColor:'rgba(152,209,217,0.7)', position:'fixed', zIndex:'30', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={onClick}>
            <PopBox animate={true} >
                <div style={{ height:'95%', width:'48%', padding:'2vh', display:'flex', flexDirection:'column', justifyContent:'space-between', backgroundColor:'white'}}>
                    <div style={{fontWeight:'bold', fontSize:'3vh'}}>
                        Restaurant Petra El Born
                    </div>
                    <div>
                        <span style={{fontWeight:'bold', fontSize:'3vh'}}>Horario Actual</span>
                        <Spacer space='1vh' />
                        {
                            horarios.map((item,i) => {
                                return( 
                                <div key={i}>
                                    <span style={{fontSize:'2.5vh'}}>{item.day}</span>
                                    <br />
                                    <span style={{fontSize:'2.5vh', opacity:'0.5'}}>{item.time}</span>
                                    <Spacer space='0.5vh' />
                                </div>)
                            })
                        }
                    </div>
                    <div style={{fontSize:'2vh'}}>
                        <b>OS ESPERAMOS!!</b><br />
                        RESERVAS ABIERTAS EN LA WEB<br />
                        <Spacer space='3vh' />
                        <b>SI QUIERE RESERVAR MESA PARA HOY,<br /> 
                        LLÁMANOS AL +34 933 19 99 99</b>
                    </div>
                </div>
                <div style={{height:'100%', width:'50%', position:'relative'}}>
                    <img src='./media/icons/cross_white.png' height='3%' width='auto' style={{position:'absolute', top:'1%', right:'1%'}} onClick={onClick} />
                    <img src='./media/details/6.jpg' height='100%' width='100%' />
                </div>

            </PopBox>
            
        </div>
    )
}

export default PopUp
