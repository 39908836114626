import { useEffect, useState } from "react";
import { Title } from "./styles/Text.styles";
import { Swiper, SwiperSlide} from "swiper/react"
import { FreeMode, Navigation, Keyboard, Mousewheel, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/less';
import 'swiper/less/navigation';
import { useTranslation } from "react-i18next";
import { SliderDiv, SliderBox } from "./styles/Containers.styles";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'
import { DetailBox } from "./styles/Containers.styles";



const RestaurantSlider = () => {

    const { t } = useTranslation()

    const [show, setShow] = useState(false)
    const [ modalSrc, setModalSrc ] = useState('')

    const data = ['/media/details/1.jpg','/media/details/2.jpg','/media/details/3.jpg','/media/details/4.jpg','/media/details/5.jpg','/media/details/6.jpg' ]

    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])
        
    const enlarge = (x) => {
        setShow(true)
        setModalSrc(x)
    }

    
    return (
    <>
            <SliderDiv  data-aos="fade-up">
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Title>{t('slider.details')}</Title>
                    <span style={{marginBottom:'auto', marginTop:'2.5vh', fontSize:'2vh',}}><Link to="/petra">{t('slider.see')}</Link></span>
                </div>
                <div style={{marginLeft:'-2vw', width:'100vw', padding:'5vh 0'}}>
                    <Swiper
                    modules={[Navigation, Keyboard, Mousewheel, FreeMode, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={3.5}
                    freeMode={true}
                    navigation
                    keyboard={{enabled: true, onlyInViewport:false}}
                    scrollbar={{ draggable: true }}
                    >
                    {data.map((item,i)=>{
                         return <SwiperSlide key={i}><SliderBox src={window.location.origin + item} onClick={() =>  enlarge(item)} /></SwiperSlide>
                    })}
                    </Swiper>
                </div>
            </SliderDiv> 
            {/* backgroundColor:'rgba(152,209,217,0.7)', */}

            {show && <div onClick={() => setShow(false)} 
            style={{cursor:'pointer', backgroundColor:'rgba(255,255,255,0.75)', position:'fixed', top:'10vh',left:'0', height:'90vh', width:'100vw',display:'flex',justifyContent:'center', alignItems:'center'}}>
    
            <DetailBox animate={false}>
            <img src={window.location.origin + modalSrc} height='100%' width='auto'/>
            </DetailBox>
            </div>}
    </>         
    )
}

export default RestaurantSlider
