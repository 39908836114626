import React from 'react'
import { MSubTitle } from '../styles/Text.styles'
import { TextInfo } from '../styles/Text.styles'
import { Spacer } from '../styles/Containers.styles'

const Footer = () => {
    return (
        <div style={{padding:'3vh', backgroundColor:'#98D1D9'}}>
            <Spacer space='3vh' />
                <MSubTitle> Carrer del Sombrerers, 13 <br/>08003 Barcelona <br/></MSubTitle>
                        <a href="tel:+34933199999"><MSubTitle className="bold">+34 933 19 99 99</MSubTitle></a>

                <Spacer space='3vh' />
                <TextInfo><a href="mailto:info@restaurantpetra.com" className="bold">info@restaurantpetra.com</a></TextInfo>
                <div style={{position:'relative', top:'2vh', display:'flex', gap:'2vw'}}>
                    
                    <TextInfo>FACEBOOK</TextInfo>
                    <TextInfo><a href="https://www.instagram.com/restaurantpetra/">INSTAGRAM</a></TextInfo>
                </div>
            <Spacer space='3vh' />
        </div>
    )
}

export default Footer

{/* <a href="tel:933199999"> */}
