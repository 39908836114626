import React from 'react'
import { Title, TextInfo } from './styles/Text.styles'
import { HomeBanner, BannerText, Spacer } from './styles/Containers.styles'
import { FormBtn } from './styles/Buttons.style'
import { useTranslation } from 'react-i18next'

const HomePageBanner = () => {

    const { t } = useTranslation()

    const banner = '/media/details/banner.jpg'

    return (
        <HomeBanner src={banner}>
            {/* <img src={window.location.origin + '/media/details/banner.png'} width='100%' height='auto'/> */}

            <BannerText>
                
                <Title style={{fontSize:'10vh', fontFamily:'WS_Medium', lineHeight:'1'}}>{t('home.title')}</Title>
                <Spacer space='4vh' />
                <div className='desc' >
                <TextInfo>{t('home.subTitle')}</TextInfo>
                </div>
                <Spacer space='8vh' />
                <FormBtn type='button' bgColor='white' hBgColor='black' color='black' hColor='white'>
                    <a href="#reserve">
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <span style={{fontSize:'2vh'}}>{t('home.Btn')}</span>
                        </div>
                    </a>
                </FormBtn>
               
            </BannerText>
        </HomeBanner>
    )
}

export default HomePageBanner
