import styled from "styled-components";
import { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { device } from './Device';


// Banner for the Home Page
export const HomeBanner = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url(${props =>  props.src});
    background-position: center;
    background-size: cover;
    /* background-repeat: no-repeat; */
`

export const BannerText = styled.div`
    color:white; 
    width:50vw; 
    position:absolute; 
    top:20vh; 
    left:5vw;



    @media ${device.mobileS} {
        width: 80vw;

        .desc{ 
            width: 60vw;    
        }
        
    }

    @media ${device.laptop} {
      width: 50vw;

      .desc{    
            width: 30vw;    
        }

    }

`

const MenuDown = keyframes `
    to {
        transform: translateY(0%);
    }
`


// Main Menu Box
export const MainMenuBox = styled.div`
    position:absolute; 
    z-index:10; 
    justify-content:center; 
    width:100vw;
    padding:0 5vw;

`


// Dark Menu on scroll
export const DarkMenu = styled.div`
    position:fixed;
    z-index: 10;
    justify-content: center;
    width:100vw;
    background-color: #98D1D9;
    transform: translateY(-150%);
    animation: ${MenuDown} 0.6s 0.1s forwards ease;

`

// HeaderBox where Menu is displayed
export const HeaderBox = styled.div`
    height: 10vh;
    width:90vw;
    display: flex;
    justify-content: space-between;
    color: ${props =>  props.color  || "white" };
    position: relative;
    
`

// Menu Nav Bar
export const MenuNavBar = styled.nav`
    width: 40vw;
    height:10vh;
    gap:4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    margin-left:calc(50% - 20vw);
`

export const MenuRightBox = styled.div`
    display:flex; 
    justify-content:center; 
    align-items:center;


    @media ${device.mobileS} {
       
        .insta{
            display: none;
        }
        
    }

    @media ${device.tablet} {
        
        .insta{
            display: block;
        }

    }

`

export const HamburgerTablet = styled.div`
    position:relative; 
    z-index:20;
    display:flex;
    justify-content:flex-end; 
    padding-top: 2vh;
    padding-right:0vh;


    @media ${device.mobileS} {
        display: block;
        
    }

    @media ${device.tablet} {
        display: none;

    }
`

// Horizontal Line/Stroke
export const Splitter = styled.hr`
    height:0.2vh;
    width: ${props => props.width || "85vw"};
    border-width: 0;
    background-color: ${props => props.bgColor || "white"};
    color: ${props => props.color || "white"};
    /* padding-left: ${props => props.left || "10vh"}; */
    padding-left: 10vh;
`

// The spacer I like
export const Spacer = styled.div`
    height: ${props => props.space};
`

// Slider Container 

export const SliderDiv = styled.div`
    height:50vh; 
    
    @media ${device.mobileS} {
        padding:7vh 5vh;
    }

    @media ${device.tablet} {
        padding:7vh 9vh;
    }

`

export const SliderBox = styled.div`
    /* height: 50vh; */
    /* width: 40vh; */
    background-image: url(${props =>  props.src});
    background-position: center;
    background-size: cover;

    @media ${device.mobileS} {
        /* background-color: red; */
        height: 30vh;
        width: 15vw;
    }

    @media ${device.mobileL} {
        /* background-color: yellow; */
        height: 40vh;
        width: 25vw;
    }

    @media ${device.laptop} {
        /* background-color: purple; */
        height: 50vh;
        width: 25vw;
    }

    
    

`

// Reservation Section

export const ReservationBox = styled.div`

    height:60vh; 
    width:90%;
    margin:7.5vh 10vh; 
    display:flex; 
    gap:10vh;
    justify-content:space-between; 

    @media ${device.mobileS} {
        height:100vh; 
        flex-direction: column;
        gap:5vh;
        margin:7.5vh 5vh; 
    }

    @media ${device.tablet} {
      height:60vh; 
      flex-direction: row;
      gap:10vh;
      margin:7.5vh 10vh; 
    }

 
`

// About Section

export const AboutBox = styled.div`
    display:flex; 
    justify-content:space-between; 
    height:60vh; 
    gap:7vw;

    @media ${device.mobileS} {
        height: auto;
        flex-direction: column;
        padding:5vh 5vh; 
    }

    @media ${device.tablet} {
        padding:5vh 10vh; 
      flex-direction: row;
    }

`

export const AboutBoxLeft= styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:40%;
    

    @media ${device.mobileS} {
        width:100%;
    }

    @media ${device.tablet} {
        width:40%;
    }

`

export const AboutBoxRight= styled.div`
  
    @media ${device.mobileS} {
        width:100%;
    }

    @media ${device.tablet} {
        width:50%;
    }

   

`

export const AboutContentBox = styled.div`
    display:flex; 
    position:relative; 
    gap:2vw;

    @media ${device.mobileS} {
        margin-top: 5vh;
       flex-direction: column;
    }

    @media ${device.tablet} {
        flex-direction: row;
        margin-top: 0;
    }

`

export const AccordionBox = styled.div`
    padding:5vh 10vh 0 10vh; 
    position:relative;

    @media ${device.mobileS} {
        padding:5vh 5vh 0 5vh; 
    }

    @media ${device.tablet} {
        padding:5vh 10vh 0 10vh; 
    }

`
export const PetraContent = styled.div`
    padding:10vh; 
    padding-bottom:20vh;


    @media ${device.mobileS} {
        width:75%;
        padding:10vh 5vh; 
    }

    @media ${device.tablet} {
        width:50%;
        padding:10vh; 
    }
`

// Menu Page Styling
export const FilterContainer = styled.div`
   
    text-align: left;
    margin-bottom: 35px;


    @media (min-width: 992px) {
    
        text-align: left;
        margin-bottom: 61px;
        
    }
`
export const FoodContainer = styled.ul`
    padding: 0;
    width:100%;
    list-style: none;  
    display: flex;
    flex-wrap: wrap;
    gap: 1.5vw;
    
    @media (min-width: 768px) {
      margin: 0 -15px;
    }
  
    @media (min-width: 1400px) {
        margin: 0 0px;
    }
`

export const ItemLi = styled.li`

    margin-bottom: 20px;

    @media (min-width: 768px) {
        margin-bottom: 10px;
        padding: 0 15px;
    }

    @media (min-width: 1400px) {
        margin-bottom: 60px;
        padding: 0 30px !important;
    }
`

export const ItemCard = styled.div`
    position: relative;
    height: 70vh;
    width:29vw;

    @media ${device.mobileS} {
        width:80vw;
    }

    /* @media ${device.mobileM} {
        width:80vw;
    }

    @media ${device.mobileL} {
        width:43vw;
    } */

    @media ${device.tablet} {
        width:43vw;
    }

    @media ${device.laptop} {
        width:29vw;
    }  


    
    :focus {
        outline: none;
    }

    @media (min-width: 992px) {
    :hover .news-card__content, .news-card__wrapper:focus .news-card__content {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
`

export const ItemCardPic = styled.div`

    background-color: white;
    height: 70vh;
    width: 30vw;
    background-image: url(${props =>  props.src});
    background-position: center;
    background-size: cover;
    transition: all 1s;


    @media ${device.mobileS} {
        width:80vw;
    }

    @media ${device.tablet} {
        width:43vw;
    }

    @media ${device.laptop} {
        width:29vw;
    }  

    :hover {
        cursor: pointer;
    }


`

const cardUp = keyframes`
    to {
        transform: translateY(-100%);
    }
`
export const ItemCardContent = styled.div`
    position: absolute;
    top:0;
    left:0;
    z-index: 2;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50vh;
    width: 30vw;
    opacity: 0;
    overflow: hidden;
    transition: all 0.8s;

    :hover{
        opacity: 1;
        cursor: pointer;
    }

    ::before{
        content:"";
        position: absolute;
        left:0;
        top:0;
        z-index: -15;
        background-color: #98D1D9;
        height: 50vh;
        width: 29vw;
        transform: translateY(100%);
        transition: all 0.3s;
    }

    :hover::before{
        transform: translateY(0);
    }
   

`

// Pop Up Box 
const open = keyframes `
    to {
        opacity: 1;
    }
`

export const PopBox = styled.div`
    height:80vh;
    width: 60vw;
    cursor: pointer;
    display:flex;
    position: relative;
    overflow:hidden;
    opacity: 0;
    animation: ${open} 0.4s forwards ease-out;
    justify-content: center;

    @media (max-width:576px) {
        margin:auto 0;
        display:block;
        height:90vh;
        width: 80vw;
    }
`

export const PopBoxM = styled.div`
    height:80vh;
    width: 80vw;
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow:hidden;
    opacity: 0;
    animation: ${open} 0.4s forwards ease-out;
    

    /* @media (max-width:576px) {
        margin:auto 0;
        display:block;
        height:90vh;
        width: 80vw;
    } */
`

const appear = keyframes `
    to {
       opacity:1;
    }
`

export const DetailBox = styled.div`
    height:80vh;
    width: 60vw;
    cursor: pointer;
    display:flex;
    overflow:hidden;
    opacity:0;
    animation: ${appear} 0.4s forwards ease-out;
    justify-content: center;

    @media (max-width:576px) {
        display:block;
        height:90vh;
        width: 80vw;
    }
`


export const FooterBox = styled.div`
    height:30vh; 
    width:100vw; 
    padding:5vh;
    padding-bottom:0vh;
    display:flex; 
    gap: 5vw;
    background-color: #98D1D9;
    color:white;


    @media ${device.mobileS} {
        padding-left:0vh ;
    }

  @media ${device.tablet} {
      padding-left:5vh ;
  }
`

export const FooterContent = styled.div`
    display:flex; 
    flex-direction:column; 
    gap:5vh;

    .footer-splitter {
        width:92%;
    }

    @media ${device.mobileS} {
      
        width: 70vw;

        .copyright {
            flex-direction: column;
        }

        .footer-splitter {
            width:52%
        }
        
    }

    @media ${device.mobileM} {
       
        width: 70vw;

        .footer-splitter {
            width:92%
        }
    }

    @media ${device.mobileL} {
   
        width: 70vw;

        .footer-splitter {
            width:92%
        }
    }

    @media ${device.tablet} {
        width: 70vw;

        .copyright {
            flex-direction: row;
        }

       
    }

    @media ${device.laptop} {
        width: 78vw;
    }


` 

// Mobile

export const MSplitter = styled.hr`
    height:0.2vh;
    width: 90vw;
    border-width: 0;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
`

export const MSliderBox = styled.div`
    /* height: 40vh;
    width: 35vh; */
    background-image: url(${props =>  props.src});
    background-position: center;
    background-size: cover;


    @media ${device.mobileS} {
        /* background-color: red; */
        height: 40vh;
        width: 70vw;
    }

    @media ${device.mobileM} {
        /* background-color: blue; */
        height: 40vh;
        width: 70vw;
    }

    @media ${device.mobileL} {
        /* background-color: yellow; */
        height: 40vh;
        width: 70vw;
    }

    @media ${device.tablet} {
        /* background-color: green; */
        height: 40vh;
        width: 20vw;
    }

`

export const BannerContainer = styled.div`
    height:100%;
    width:100%;
    color:white;
    top:10vh; 
    padding:15vh 15vh 0vh 1.5vh;
    background-image:url(${props => props.src});
    background-size:120vh;
    background-position-x: 50%;
    background-repeat: no-repeat;
`

// Accordian CSS 

const enterText = keyframes`${fadeIn}`;
 
export const AccordianAnimation = styled.div`
  animation: ${(props) => props.delay} ${enterText};
`



// const slideUp = keyframes `
//     to {
//         transform: translateY(0%);
//     }
// `


export const AccordianItem = styled.div`
   overflow:hidden;
   height: auto;
   transition: all 2s cubic-bezier(0,1,0,1);
` 
export const AccordionItemHeader = styled.button`

    position: relative;
    width: 100%;
    padding: 2vh 0vh;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display:flex;
    justify-content: space-between;


    ::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 25px;
        height: 25px;
        //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='black' d='M13.5 0v25M25 12.5H0'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    @media ${device.mobileS} {

    }

    @media ${device.tablet} {

    }


    /* @media (max-width:576px) {
        padding: 2vh 0vh;
    } */
` 

export const AccordianHeaderBox = styled.span`
    display:flex; 
    justify-content:space-between; 
    flex-grow:2; 
    margin-right:5vw;

    @media ${device.mobileS} {
        flex-direction: column;
    }

    @media ${device.tablet} {
        flex-direction: row;
    }

`

export const AccordianItemTitle = styled.span`
    margin-bottom: 8px;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.5;


    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    @media (max-width:576px) {
        font-size: 4vh;
    }
`

export const AccordianItemShort = styled.span`
    max-width: 34vw;
    font-size: 15px;
    line-height: 1.5;



    @media ${device.mobileS} {
        max-width: 84vw;
    }

    @media ${device.tablet} {
        max-width: 34vw;
    }
`
const bodyOpen = keyframes `
    to {
       height:50vh;
       opacity: 1;
    }
`    

export const AccordianItemBody = styled.div`
    padding-left:0vh;
    padding-right:0vh;
    padding-bottom: 5vh;
    height:0vh;
    overflow:hidden;
    opacity:0;

    

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    @media (max-width:576px) {
        padding-left:0vh;
        padding-right:0vh;
        padding-bottom: 5vh;

        img{
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
`
export const AccordianRow = styled.div`
    display:flex;
    justify-content: space-between;
    margin-bottom: 5vh;
    gap:10vw;

    @media ${device.mobileS} {
        flex-direction:column;
    }

    @media ${device.tablet} {
        flex-direction:row;
    }
`

export const AccordianItemText = styled.div`
    max-width: 505px;
    margin-top: 24px;
    font-size: 15px;
    line-height: 1.5;

    @media (min-width: 1200px) {
        margin-top: 46px;
    }

    @media (max-width:576px) {
        font-size: 2vh;
    }

    @media ${device.mobileS} {
        max-width: 84vw;
    }

    /* @media ${device.tablet} {
        max-width: 34vw;
    } */

    @media ${device.laptop} {
        max-width: 34vw;
    }

`
export const AccordianLeft = styled.div`
    width:50%;

    @media ${device.mobileS} {
        width: 84vw;
    }

    /* @media ${device.tablet} {
        max-width: 34vw;
    } */

    @media ${device.laptop} {
        width: 34vw;
    }

   
`
export const AccordianRight = styled.div`
    /* width:40%; */
    margin-right:6vw;
    font-family:WS_Regular;

    /* @media (max-width:576px) {
        width:100%;
        padding-left: 0;
    } */

    @media ${device.mobileS} {
        max-width: 84vw;

        .image-2{
            width:100%;
            height:auto
        }
    }

    /* @media ${device.tablet} {
        max-width: 34vw;
    } */

    @media ${device.laptop} {
        max-width: 34vw;

        .image-2{
            width:40%;
            height:auto
        }
    }
    
`

// Mobile

export const MAccordionItemHeader = styled.button`

    position: relative;
    width: 100%;
    padding: 2vh 0vh;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    border: none;
    justify-content: space-between;


    ::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 25px;
        height: 25px;
        //background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='black' d='M13.5 0v25M25 12.5H0'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    @media (max-width:576px) {
        padding: 2vh 0vh;
    }
` 

export const MItemCard = styled.div`
    position: relative;
    height: 50vh;
    width:90vw;
    background: red;
    
    :focus {
        outline: none;
    }

    /* @media (min-width: 992px) {
    :hover .news-card__content, .news-card__wrapper:focus .news-card__content {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }} */

`

export const MItemCardPic = styled.div`

    background-color: white;
    height: 50vh;
    width: 90vw;
    background-image: url(${props =>  props.src});
    background-position: center;
    background-size: cover;
    transition: all 1s;

    :hover {
        cursor: pointer;
    }


`
