const es = {

    menu: {
        menu:"CARTA",
        contact:"CONTACTO",
        petra: "PETRA",
        gift: "REGALO" 
    },
    home: {
        title:"Tradición catalana con un toque pintoresco",
        subTitle:"Platos catalanes tradicionales y de temporada en un restaurante pintoresco, con azulejos llamativos y vidrieras.",
        Btn :"RESERVAR MESA",
        reserve:"SI QUIERE RESERVAR MESA PARA HOY, POR FAVOR, LLÁMANOS"
    },
    slider:{
        menu:"Carta",
        details:"Detalles",
        see:"VER MÁS",
        complete:"VER MENÚ COMPLETO",

    },
    form :{
        title:'Reservar',
        name: "Nombre",
        email: "Correo Electrónico",
        mobile:"Móvil",
        people:"Personas",
        reserve:"RESERVA",
        reservation:"Reservación",
        msg:"¡Nos pondremos en contacto con usted pronto!",
        error:"¡Todos los campos son obligatorios!",
        new:"Hacer otra reserva"
    },
    about: {
        sub:"Petra es un restaurante de Barcelona ubicado en el bonito barrio del Born, un acogedor restaurante con un estilo muy propio.",
        desc:"Colindante a la impresionante Basílica de Santa María del Mar, lugar que propició la inspiración de auténticos éxitos literarios como La Catedral del Mar de Ildefonso Falcones.Si ya conoces el barrio del Born, también denominado el barrio de la Ribera, podrás situarnos fácilmente desde la fachada de la Basílica de Santa María del Mar.",
        more:"SABER MÁS",
        msg:"No queríamos presentarte nuestro restaurante sin más, ya que nuestro principal cometido es el de servir cenas, comidas y menús de grupos.",
        wait:"¡Te esperamos! Sam & Joan",
        petra:{
            title:"PETRA",
            text1:"El Petra es un restaurante de Barcelona ubicado en el bonito barrio del Born, un acogedor restaurante con un estilo muy propio. Colindante a la impresionante Basílica de Santa María del Mar, lugar que propició la inspiración de auténticos éxitos literarios como La Catedral del Mar de Ildefonso Falcones.",
            text2:"El Petra es un restaurante en un lugar con encanto, en una calle donde años atrás el gremio de sombrereros de Barcelona comerciaban y convivían en este auténtico «carreró del barri de la Rivera», un lugar con el aroma de la Barcelona de principios del siglo XV, un barrio de pescadores a tan sólo 200 metros del mar. El Petra es un restaurante donde podrás descubrir pequeñas cosas como sus artesanas vidrieras emplomadas que conjuntan finos cristales de colores, la simpáticas lámparas, las paredes repletas de baldosines, las sillas de madera, que de vez en cuando crujen con encanto, los espejos, los muebles como los de la yaya, nuestros aseos con un marcado estilo modernista."
        },
        born:{
            title:"EL BORN",
            text1:"Si ya conoces el barrio del Born, también denominado el barrio de la Ribera, podrás situarnos fácilmente desde la fachada de la Basílica de Santa María del Mar.  Mirándola de frente nuestro restaurante te quedará a tu izquierda, ¿Te sitúas?",
            text2:"Estamos en esa calle estrecha flanqueada por la alta e imponente pared de piedra de la basílica de Santa María del Mar, pura piedra, piedra que le dió nombre al restaurante, «Petra» del latín «piedra». Ese nombre que fue garabateado a mano alzada por Ada Parellada, la mestressa  del Semproniana ( www.semproniana.net ) que se hizo cargo del restaurante desde el año 2000 hasta el 2012, aunque la historia del Petra comenzó mucho antes, por allá el año 1975 cuando su nombre era «El Carpanta», hoy somos Sam y Joan los que llevamos el restaurante.Nos encontrarás  en la calle Sombrerers número 13, en la esquina con la calle Banys Vells (Baños Viejos), estamos más o menos a la mitad del templo, justo debajo de las campanas que repican con nombre propio, L’Andrea, La Maria Salvatella, La Conventual (Quaresma) y L’Assumpta."
        },
        arrive:{
            title:"COMO LLEGAR",
            text1:"Si conoces un poco el Born te será muy fácil localizarnos, desde el Passeig del Born puedes ir hasta la fachada de la iglesia de Santa María del Mar, nuestra calle, mirando la fachada te quedará a tu izquierda.",
            text2:"Si vienes del centro son 10 minutos a pie, bien sea desde la catedral o de la Plaza Catalunya o Plaza Sant Jaume lo mejor será llegar a Via Laietana e ir bajando hasta el Carrer de l’Argenteria, esta calle te llevará hasta la misma fachada de Santa María del Mar. Te mostramos algunos de los transportes públicos que te dejan cerca, ah! y recuerda reservar."
        }
    },
    card:{
        title:"Para Comer",
        card:"CARTA",
        zapping:"MENÚ ZAPPING",
        midday:"MENÚ DEL MEDIODÍA",
        wines:"VINOS"
    },
    contact:{
        contact:"Contacto"
    },
    cookie:{
        perfect:"¡Perfecto!",
        msg:"Esta web utiliza cookies para mejorar la experiencia del usuario."
    }

}

export default es