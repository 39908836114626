import { useEffect, useState, useRef } from 'react'
import { NavLink, Link, LinkProps } from 'react-router-dom'
import { HeaderBox, MenuNavBar, DarkMenu } from './styles/Containers.styles'
import { MenuTitle, MenuTextLink } from './styles/Text.styles'
import { MainMenuBox, MenuRightBox, Splitter } from './styles/Containers.styles'
import Languages from './Languages'
import { useTranslation } from "react-i18next"


const MainMenu = ({color, selected, selectedD, hamburger}) => {

    const menuRef = useRef()

    const linkRef = useRef(null);

    useEffect(() => {
       if (linkRef.current) {
        //   linkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        linkRef.current.scrollIntoView(0,0);
       }
    }, []);

    const { t, i18n } = useTranslation()
    const [ dark, setDark] = useState(false)

    let activeStyle = {
        color: 'red'
    };

    let activeStyleD = {
        color: 'red'
    };

    const scrollHandler = () => {
        if(menuRef.current.getBoundingClientRect().bottom < 0){
            setDark(true)
        } else {
            setDark(false)
        }
    };


    useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);

    },[])

   
    return (
        <>
        {/* <div style={{position:'absolute', zIndex:'10', justifyContent:'center', width:'100vw'}} ref={menuRef}>
            <div style={{padding:'0 5vw'}}> */}
            <MainMenuBox ref={menuRef}>
                <HeaderBox color={color}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        {/* <Link to="/"><MenuTitle color={color}>Petra</MenuTitle></Link> */}
                        <NavLink ref={linkRef} to="/">{color ? <img src={window.location.origin + '/media/icons/petraWB.png'} height='30vh' width='auto' /> : <img src={window.location.origin + '/media/icons/PetraW.png'} height='30vh' width='auto' />}</NavLink>
                    </div>
                        <MenuNavBar>
                            <MenuTextLink ref={linkRef} to="/menu" color={color} selected={selected}>{t('menu.menu')}</MenuTextLink>
                            <MenuTextLink ref={linkRef} to="/petra" color={color} selected={selected}>{t('menu.petra')}</MenuTextLink>
                            <MenuTextLink ref={linkRef} to="/contact" color={color} selected={selected}>{t('menu.contact')}</MenuTextLink>
                        </MenuNavBar>
                    <MenuRightBox>
                        <Languages color={color} sColor='#98D1D9' />
       
                        {/* <Hamburger /> */}
                        <a href="https://www.instagram.com/restaurantpetra/" className="insta">
                        &nbsp;&nbsp;&nbsp;&nbsp;{color ? <img src={window.location.origin + '/media/icons/instagram.png'} height='35vw' width='auto' />:<img src={window.location.origin + '/media/icons/instagram_white.png'} height='35vw' width='auto' /> }
                        </a>
                    </MenuRightBox>
                </HeaderBox>
                <Splitter color={color} bgColor={color} style={{marginLeft:'0vw'}} />
            </MainMenuBox>
            
            {/* </div>
        </div> */}
       { dark && <DarkMenu>
                    <div style={{padding:'0 5vw'}}>
                        <HeaderBox color="white">
                            <div style={{display:"flex", alignItems:"center"}}>
                            <NavLink ref={linkRef} to="/">
                                    <img src={window.location.origin + '/media/icons/PetraW.png'} height='30vh' width='auto' />
                                </NavLink>
                            </div>
                            <MenuNavBar>
                                <MenuTextLink ref={linkRef} to="/menu" selectedD={selectedD}>{t('menu.menu')}</MenuTextLink>
                                <MenuTextLink ref={linkRef} to="/petra" selectedD={selectedD}>{t('menu.petra')}</MenuTextLink>
                               
                                <MenuTextLink ref={linkRef} to="/contact" selectedD={selectedD}>{t('menu.contact')}</MenuTextLink>
                            </MenuNavBar>
                            <MenuRightBox>
                                <Languages sColor='black' />
                               
                                <a href="https://www.instagram.com/restaurantpetra/" className="insta">
                                &nbsp;&nbsp;&nbsp;&nbsp; <img src={window.location.origin + '/media/icons/instagram_white.png'} height='35vh' width='auto' />
                                </a>
                            </MenuRightBox>
                        </HeaderBox>
                    </div>
                </DarkMenu>}
    </>
    )
}

export default MainMenu
