import styled from "styled-components";

export const ButtonL = styled.button`
  border:none;
  font-size: clamp(1rem, 1vw + 0.2rem, 1.8rem);
  font-family: WS_Medium;
  background-color: transparent;
  color:${props => props.color || "white"};

  @media (max-width: 576px) {
    font-size: clamp(3vh, 1.2vh + 0.2vh, 2vh);
    color:${props => props.color || "black"};
    
    }

    :hover{
        font-weight: bold;
        cursor: pointer;
    }
`

export const ButtonLT = styled.button`
  border:none;
  font-size: clamp(0.75rem, 1vw + 0.2rem, 1.8rem);
  font-family: WS_Medium;
  background-color: transparent;
  color:${props => props.color || "white"};

  @media (max-width: 576px) {
    font-size: clamp(0.75rem, 1vw + 0.2vh, 2vh);
    color:${props => props.color || "white"};
    
    }

    :hover{
        font-weight: bold;
        cursor: pointer;
    }


`

export const FormBtn = styled.button`
    background-color: ${props => props.bgColor};
    border: 1px solid #000;
    font-family:WS_Medium;
    /* width:15vw; */
    color: ${props => props.color};
    padding: 2vh;
    cursor: pointer;
    text-align: left;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-align: center;

    :hover {
        background-color:  ${props => props.hBgColor};
        color: ${props => props.hColor};
    }
    :focus {
        outline:none;
    }
`

// Menu Page Filter Buttons

export const MenuCategory = styled.button`
  position: relative;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  margin-right: 10px;
  font-family: "Neue Montreal", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
  text-transform: capitalize;
  cursor: pointer;
  background-color: transparent;
  border:none;
  /* border-bottom: 1px solid black; */
  transition: all 1s ease-in;
  /* border-color:${props => props.selected ? "#98D1D9" : "black"}; */

  ::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: red;
    border-top: solid 1px black;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }

  @media (min-width: 1200px) {
      margin-right: 5vh;
  }

  :last-child {
  margin-right: 0;
  }

  :focus {
    outline: none;
  }

  @media (min-width: 300px) {
    :hover, :focus {
      outline: none;
    }
    :hover::before, :focus::before {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
`















// Mobile
export const MFormBtn = styled.button`
    background-color: ${props => props.bgColor};
    border: 1px solid #000;
    width:max-content;
    color: ${props => props.color};
    padding: 1.5vh;
    cursor: pointer;
    text-align: left;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-align: center;

    :hover {
        background-color:  ${props => props.hBgColor};
        color: ${props => props.hColor};
    }
    :focus {
        outline:none;
    }
`

export const MenuBurger = styled.div`
  
  transform: ${({ open }) => open ? 'translateX(-75vw)' : 'translateX(100vw)'};
  height:110vh;
  width:120vw !important;
  padding:10vw;
  padding-top:0;
  text-align: left;
  position: absolute;
  z-index:40;
  top: -10vh;
  left: 0vw;
  background-color: #98D1D9;
  transition: transform 0.5s ease-in-out;
  display:flex;
  flex-direction: column;
  justify-content: center;
  

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 1vh;
    width:100%;
    padding: 2.21vh 0 2.21vh 2.21vh;
    /* font-weight: bold; */
    letter-spacing: 0.5rem;
    color: black;
    background-color: #98D1D9;
    text-align:left;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 5vh;
      text-align: left;
    }

    /* &:hover {
      color: #FF874B;
    } */
  }
`

export const MenuBurgerTablet = styled.div`
  
  transform: ${({ open }) => open ? 'translateX(-110vw)' : 'translateX(100vw)'};
  height:110vh;
  width:110vw !important;
  padding:20vw;
  padding-top:0;
  text-align: left;
  position: absolute;
  z-index:40;
  top: -10vh;
  left: 10vw;
  background-color: #98D1D9;
  transition: transform 0.5s ease-in-out;
  display:flex;
  flex-direction: column;
  justify-content: center;
  

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: clamp(2rem, 1vw + 0.2rem, 1.8rem);
    width:100%;
    padding: 2.21vh 0 2.21vh 2.21vh;
    /* font-weight: bold; */
    letter-spacing: 0.5rem;
    color: black;
    background-color: #98D1D9;
    text-align:left;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 5vh;
      text-align: left;
    }

    /* &:hover {
      color: #FF874B;
    } */
  }
`

export const StyledBurger = styled.button`
  top: 2vh;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 5px;
  background:none;
  

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    
    background: ${({ open }) => open ? 'black' : props => props.color };
    /* background-color: black; */
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    z-index: 50;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`


