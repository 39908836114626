import {useState} from 'react'
import { ItemCard, ItemCardPic, ItemCardContent } from './styles/Containers.styles'

const MenuItem = ({src, title, description}) => {

    return (
        <ItemCard>
            <div style={{overflow:'hidden'}}>
                <ItemCardPic src={src} />
                    {/* <img src={src} height='100%' width='100%' alt={title} />
                </ItemCardPic> */}
            </div>
            {/* {<ItemCardContent>
                <div>{title}</div>
                <div>{description}</div>
            </ItemCardContent>} */}
        </ItemCard>

    )
}

export default MenuItem
