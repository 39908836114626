import React from 'react'
import { Title, TextInfo } from '../styles/Text.styles'
import { Spacer } from '../styles/Containers.styles'
import { MFormBtn } from '../styles/Buttons.style'
import Hamburger from './Hamburger'
import { MSplitter } from '../styles/Containers.styles'
import { BannerContainer } from '../styles/Containers.styles'
import { useTranslation } from 'react-i18next'

const HomePageBanner = ({color}) => {

    const {t} = useTranslation()
    return (
        <div style={{height:'80vh', width:'100vw', position:'relative'}}>
            <div style={{position:'absolute',padding:'1vh', width:'90vw'}}>
                <img src={window.location.origin + '/media/icons/PetraW.png'} height='30vh' width='auto' style={{marginTop:'0.5vh', marginLeft:'3vw'}}/>
                <MSplitter color={color} bgColor={color} style={{marginLeft:'3vw'}}/>
            </div>
            <BannerContainer src="./media/details/banner.jpg">
                <div style={{ paddingRight:'10vw'}}>
                    <Title style={{fontSize:'6vh', fontFamily:'WS_Medium'}}>{t('home.title')}</Title>
                </div>
                <Spacer space='6vh' />
                <div style={{ marginRight:'5vh'}}>
                <TextInfo>{t('home.subTitle')}</TextInfo>
                </div>
                <Spacer space='10vh' />
                <MFormBtn type='button' bgColor='white' hBgColor='black' color='black' hColor='white' >
                    <a href="#reserve"><div style={{display:'flex', justifyContent:'space-between'}}>
                        <TextInfo style={{fontFamily:'WS_Medium'}}>{t('home.Btn')}</TextInfo>
                    </div></a>
                </MFormBtn>
            </BannerContainer>
        </div>
    )
}

export default HomePageBanner
