import { useEffect } from "react";
import { Title, TextInfo } from "./styles/Text.styles";
import { Swiper, SwiperSlide} from "swiper/react"
import { FreeMode, Navigation, Pagination, Scrollbar, Keyboard, Mousewheel, A11y } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation'
// import 'swiper/css/keyboard'
// import 'swiper/css/mousewheel'
import { useTranslation } from "react-i18next";
import { SliderDiv, SliderBox } from "./styles/Containers.styles";
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'



const MenuSlider = () => {

    const { t } = useTranslation()
    
    useEffect(() => {
        Aos.init({duration: 500, once:true})
    },[])

    return (
        <SliderDiv  data-aos="fade-up">
            <div style={{display:'flex',justifyContent:'space-between',position:'relative', zIndex:'1'}}>
                <Title >{t('slider.menu')}</Title>
                <span style={{marginBottom:'auto', marginTop:'2.5vh', fontSize:'2vh',}}><Link to="/menu">{t('slider.complete')}</Link></span>
            </div>
            <div style={{marginLeft:'-2vw', width:'100vw', padding:'5vh 0'}}>
                <Swiper
                modules={[Navigation, Keyboard, Mousewheel, FreeMode, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                slidesPerView={3.5}
                freeMode={true}
                navigation
                keyboard={{enabled: true, onlyInViewport:true}}
                scrollbar={{ draggable: true }}
                >
                    <SwiperSlide>
                    <Link to='/menu/card'> 
                        <div style={{width:'50vh', height:'80vh', display:'flex', flexDirection:'column'}}>
                            <div>
                                <SliderBox src={window.location.origin + '/media/menu/carta_2.jpg'} />
                            </div>
                            
                                <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                    <TextInfo>{t('card.card')}</TextInfo>
                                    {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                </div>
                           
                        </div>
                    </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <Link to='/menu/zapping'>
                        <div style={{width:'50vh', height:'80vh', display:'flex', flexDirection:'column'}}>
                            <div>
                                <SliderBox src={window.location.origin + '/media/menu/zapping_1.jpeg'} />
                            </div>
                            
                                <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                    <TextInfo>{t('card.zapping')}</TextInfo>
                                    {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                </div>
                      
                        </div>
                    </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                    <a href="https://www.instagram.com/restaurantpetra/">
                        <div style={{width:'50vh', height:'80vh', display:'flex', flexDirection:'column'}}>
                            <div>
                                <SliderBox src={window.location.origin + '/media/menu/midday.jpg'} />
                            </div>
                           
                                <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                    <TextInfo>{t('card.midday')}</TextInfo>
                                    {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                </div>
                            
                        </div>
                    </a>
                    </SwiperSlide>
                    <SwiperSlide>
                    {/* <Link to='/menu/wines'> */}
                        <div style={{width:'50vh', height:'80vh', display:'flex', flexDirection:'column'}}>
                            <div>
                                <SliderBox src={window.location.origin + '/media/menu/wines.png'} />
                            </div>
                           
                                <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                <TextInfo>{t('card.wines')}</TextInfo>
                                    {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                </div>
                           
                        </div>
                    {/* </Link> */}
                    </SwiperSlide>
                </Swiper>
            </div>
        </SliderDiv>
    )
}

export default MenuSlider
