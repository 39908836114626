import {useState, useEffect} from 'react'
import MainMenu from '../components/MainMenu'
import AboutSmall from '../components/Mob/AboutSmall'
import HomePageBanner from '../components/Mob/HomePageBanner'
import MenuSlider from '../components/Mob/MenuSlider'
import RestaurantSlider from '../components/Mob/RestaurantSlider'
import { ReserveSection } from '../components/Mob/ReserveSection'
import { MSplitter, Spacer } from '../components/styles/Containers.styles'
import Hamburger from '../components/Mob/Hamburger'
import PopUp from '../components/Mob/PopUp'
import AnimatedPages from '../components/Animations/AnimatedPages'
import { TextInfo, MSubTitle } from '../components/styles/Text.styles'
import Footer from '../components/Mob/Footer'



const Home = () => {

    const [ show, setShow ]  = useState(false)

    const [scrollDir, setScrollDir] = useState("scrolling down");
    const [ white, setWhite] = useState('white')

    const onClick = () => {
        setShow(false)
    }
 

    useEffect(() => {    
            const updateScrollDir = () => {
                const scrollY = window.pageYOffset;


                if(scrollY >= 0.60 * window.innerHeight){
                setWhite('black')
                } else {
                    setWhite('white')
                }
            };

            const onScroll = () => {
                window.requestAnimationFrame(updateScrollDir);
            };

        window.addEventListener("scroll", onScroll);

        if ( sessionStorage.getItem("PopUp") == "true") {
        } else {
            setTimeout(() => {
                setShow(true)
                sessionStorage.setItem("PopUp", "true")
            }, 2000 )
        }

    }, []);

 
    return (
        <AnimatedPages>
            { show && <PopUp onClick={onClick}/>}
            <div style={{position:'fixed', top:'0', right:'2%', zIndex:'5', padding:'1vh', width:'24vw'}}>
                <Hamburger color={white} />
            </div>
            <HomePageBanner color={white}/>
            <MenuSlider />
            <Spacer space='5vh'/>
            <MSplitter color='black' bgColor='black' />
            <ReserveSection />
            <Spacer space='4vh'/>
            <MSplitter color='black' bgColor='black' />
            <AboutSmall />
            <Spacer space='12vh' />            
            <MSplitter color='black' bgColor='black' />
            <RestaurantSlider />
            <Spacer space='5vh' />  
                {/* <div style={{padding:'3vh', backgroundColor:'#98D1D9', marginTop:''}}>
                    <MSubTitle> Carrer del Sombrerers, 13 08003 Barcelona <br/>
                    +34 933 19 99 99 <br/> INFO@RESTAURANTPETRA.COM</MSubTitle>

                    <div style={{position:'relative', top:'2vh', display:'flex', gap:'2vw'}}>
                        <TextInfo>FACEBOOK</TextInfo>
                        <TextInfo><a href="https://www.instagram.com/restaurantpetra/">INSTAGRAM</a></TextInfo>
                    </div>
                </div> */}
            <Footer />
        </AnimatedPages>
    )
}

export default Home
