import React from 'react'
import { StyledBurger } from '../styles/Buttons.style'

const Burger = ({ color, open, setOpen }) => {
    return (
      <StyledBurger color={color} open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    )
  }

export default Burger
