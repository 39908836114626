import React from 'react'
import { MSubTitle, TextInfo } from '../styles/Text.styles'
import { Link } from 'react-router-dom'
import { Spacer } from '../styles/Containers.styles'
import { useTranslation } from 'react-i18next'

const AboutSmall = ({know}) => {

    const { t } = useTranslation()

    return (
        <div style={{display:'flex', flexDirection:'column',height:'50vh', padding:'2vh', gap:'2vh', paddingBottom:'10vh'}}> 
            <MSubTitle style={{fontWeight:'500', fontFamily:'WS_Medium'}}>
                {t('about.sub')}
            </MSubTitle>
            <div style={{}}>
                     <img src={window.location.origin + '/media/details/4.jpg'} width='100%' height='100%' />
            </div>
            <div style={{display:'flex', position:'relative', gap:'2vw'}}>
                {know &&  <span style={{marginTop:'auto', fontSize:'2vh',width:'40%'}}><Link to="/about">{t('about.more')}</Link></span>}
                <span style={{fontSize:'2vh', right:'0'}}>
                    {t('about.desc')}
                </span>
            </div>
            
        </div>
    )
}

export default AboutSmall
