import React from 'react'
import { MTitle, MSubTitle, TextInfo } from '../styles/Text.styles'
import { Splitter, Spacer } from '../styles/Containers.styles'
import ReservationForm from './ReservationForm'
import { useTranslation } from 'react-i18next'



// margin of parent div 15vh will be for the gap of Splitter with window edge

export const ReserveSection = ({title}) => {

    const  { t } = useTranslation()

    return (
        <div style={{padding:'2vh', marginLeft:'1vw'}} id='reserve'>
            <MTitle >{title || t('form.title')}</MTitle>
            <Spacer space='2vh'/>
            <div style={{height:'60vh', padding:'2vh', paddingLeft:'0'}}>
                <ReservationForm title={title}/>
            </div>

            <Spacer space='4vh'/>
        </div>

    )
}
