import { useState, useEffect } from 'react'
import Hamburger from '../components/Mob/Hamburger'
import LogoLine from '../components/Mob/LogoLine'
import { Spacer, AccordianItem, MAccordionItemHeader, AccordianItemBody, AccordianItemTitle, AccordianItemShort, AccordianItemText, AccordianRow, AccordianLeft, AccordianRight } from '../components/styles/Containers.styles'
import AboutSmall from '../components/Mob/AboutSmall'
import { MSubTitle, TextInfo } from '../components/styles/Text.styles'
import AnimatedPages from '../components/Animations/AnimatedPages'
import { MSplitter } from '../components/styles/Containers.styles'
import { useTranslation } from "react-i18next"
import Footer from '../components/Mob/Footer'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Petra = () => {

    const [selected, setSelected] = useState(null)

    const {t} = useTranslation()

    const toggle = (i) => {
        if (selected === i){
            return setSelected(null)
            
        }
        setSelected(i)
    }

    const data = [
        {
            title:t('about.petra.title'),
            src:"/media/details/3.jpg",
            text1:t('about.petra.text1'),
            src2:"/media/details/2.jpg",
            text2:t('about.petra.text2')
        },
        {
            title:t('about.born.title'),
            src:"/media/general/outside.jpg",
            text1:t('about.born.text1'),
            src2:"/media/details/9.jpg",
            text2:t('about.born.text2')
        },
        {
            title:t('about.arrive.title'),
            src:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5480026388964!2d2.1795946147619993!3d41.38390400417514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2fef651dacd%3A0xb1b07ad4d0029760!2sRestaurant%20Petra!5e0!3m2!1sen!2ses!4v1651224666584!5m2!1sen!2ses" width="100%" height="100%"  style={{border:'0'}}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>,
            text1:t('about.arrive.text1'),
            src2:"/media/details/8.jpg",
            text2:t('about.arrive.text2')
        },
    ]

    useEffect(() => {

        Aos.init({duration: 1000, once:true})
        
    },[])

    return (
        <AnimatedPages>
            <div style={{position:'fixed', top:'0', right:'2%', zIndex:'5', padding:'1vh', width:'24vw'}}>
                <Hamburger color='black' />
            </div>
            <LogoLine />
            <Spacer space='8vh'/>
                <AboutSmall />
                <Spacer space='12vh'/>
                <div  style={{padding:'2vh', position:'relative'}}>
                    <MSubTitle style={{fontWeight:'500', fontFamily:'WS_Medium'}}v>
                        Sobre Nosotros
                    </MSubTitle>
                    { data.map((item,i) => (
                        <AccordianItem key={i} data-aos="fade-up" >
                            <MSplitter color='black' bgColor='black' />
                            <MAccordionItemHeader  onClick={() => toggle(i)}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <AccordianItemTitle>{item.title}</AccordianItemTitle>
                                        <div style={{margin:'auto 0'}}>
                                            { selected === i ?  <img src={window.location.origin + '/media/icons/minus.png'}  width='20vh' height='1vh'/> : <img src={window.location.origin + '/media/icons/plus.png'} height='20vh' width='auto' />}
                                        </div>
                                    </div>
                                    <Spacer space='1vh'/>
                                    <AccordianItemShort>{item.text1}</AccordianItemShort>
                            </MAccordionItemHeader>
                            <div className={selected === i ? 'acc-text show': 'acc-text'} onClick={() => toggle(i)}>
                                <AccordianRow>
                                    { i < 2 && <AccordianLeft>
                                                    <div>
                                                        <img src={window.location.origin + item.src}  width='100%' height='auto' />
                                                    </div>
                                    </AccordianLeft> }
                                    { i == 2 && <AccordianLeft>
                                        <div style={{height:'100%', MarginRight:'10vh'}}>{item.src}</div>
                                    </AccordianLeft> }
                                    <AccordianRight>
                                        <AccordianItemText>
                                            {item.text2}
                                        </AccordianItemText>
                                        <Spacer space='2vh' />
                                        <img src={window.location.origin + item.src2} height='auto' width='100%'/>
                                    </AccordianRight>
                                </AccordianRow>
                            </div>
                        </AccordianItem>

                        ))
                    }
                </div>
                <MSplitter color='black' bgColor='black' />
                
                <div style={{padding:'2vh'}}>
                        <MSubTitle style={{fontWeight:'500'}}>
                           {t('about.msg')}
                            <Spacer space='5vh' />
                           <b>{t('about.wait')}</b>
                        </MSubTitle>
                </div>
                <Footer />     
        </AnimatedPages>
    )
}

export default Petra
