import React from 'react'
import { Title , SubTitle, TextInfo} from './styles/Text.styles'
import { FooterBox, FooterContent, Spacer,Splitter } from './styles/Containers.styles'
import { useTranslation } from 'react-i18next'


const Footer = () => {

    const { t } = useTranslation()

    return (
        <FooterBox>
            <div>
                <img src={window.location.origin + '/media/icons/petra_white.png'} height='100%' width='auto' />
            </div>
           
            <FooterContent>
                    <div>
                        <TextInfo>Carrer del Sombrerers, 13 <br/> 08003 Barcelona- El Born</TextInfo>
                        <Spacer space='2vh' />
                        <TextInfo><a href="tel:+34933199999" className="bold">+34 933 19 99 99</a> <br/> <a href="mailto:info@restaurantpetra.com" className="bold">info@restaurantpetra.com</a></TextInfo>
                    </div>
                    <div>
                        <Spacer space='1vh' />
                        <Splitter color='white' bgColor='white' style={{marginTop:'2vh'}} className='footer-splitter' />
                        <div style={{display:'flex', justifyContent:'space-between'}} className='copyright' >
                            <TextInfo>&#169; Petra 2022, All Rights Reserved</TextInfo>
                            <TextInfo>Privacy Policy</TextInfo>

                        </div>
                    </div>
            </FooterContent>
        </FooterBox>
    )
}

export default Footer
