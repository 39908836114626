import { MTitle } from "../styles/Text.styles";
import { Swiper, SwiperSlide} from "swiper/react"
import { FreeMode, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css'
import 'swiper/css/pagination'
import { useTranslation } from "react-i18next";
import { MSliderBox } from "../styles/Containers.styles";
import { Link } from 'react-router-dom'
import { Title, TextInfo } from "../styles/Text.styles";




const MenuSlider = () => {


    const {t} = useTranslation()
    // const Box = ({src}) => {

    //     return (<div style={{height:'40vh', width:'60vw'}}>
    //         <img src={src}  width='100%' height='auto' />
    //     </div>)
    // }

    
    return (
        <div style={{height:'50vh', padding:'2vh'}} >
            <MTitle>{t('slider.menu')}</MTitle>

            <Swiper
            modules={[ Navigation, FreeMode, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1.25}
            freeMode={true}
            // pagination={{ clickable: false }}
            scrollbar={{ draggable: true }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            style={{marginLeft:'-4.5vh'}}
            >

            {/* <Swiper
            modules={[ FreeMode, Pagination, Scrollbar, A11y]}
            // spaceBetween={40}
            width={40}
            slidesPerView={1.25}
            freeMode={true}
            // pagination={{ clickable: false }}
            scrollbar={{ draggable: true }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            style={{marginLeft:'-4.5vh'}}
            // breakpoints = {{
            //     100: {
            //         width:
            //         spaceBetweenSlides: 60
            //     },
            //     376: {
            //         spaceBetweenSlides: 60
            //     }
            // }}
            > */}
    
            <SwiperSlide>
            <Link to='/menu/card'>
                <div style={{width:'85vw', height:'80vh', display:'flex', flexDirection:'column'}}>
                    <MSliderBox src={window.location.origin + '/media/menu/carta_2.jpg'} />
                   
                                    <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                        <TextInfo>{t('card.card')}</TextInfo>
                                        {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                    </div>
            
                </div>
            </Link>
            </SwiperSlide>
            <SwiperSlide>
            <Link to='/menu/zapping'>
                <div style={{width:'75vw', height:'80vh', display:'flex', flexDirection:'column'}}>
                    <MSliderBox src={window.location.origin + '/media/menu/zapping_1.jpeg'} />
                    
                                    <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                        <TextInfo>{t('card.zapping')}</TextInfo>
                                        {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                    </div>
                    
                </div>
            </Link>
            </SwiperSlide>
            <SwiperSlide>
            <a href="https://www.instagram.com/restaurantpetra/">
                <div style={{width:'75vw', height:'80vh', display:'flex', flexDirection:'column'}}>
                    <MSliderBox src={window.location.origin + '/media/menu/midday.jpg'} />
                    
                                    <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                        <TextInfo>{t('card.midday')}</TextInfo>
                                        {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                    </div>
                
                </div>
            </a>
            </SwiperSlide>
            <SwiperSlide>
            <Link to='/menu/zapping'>
                <div style={{width:'75vw', height:'80vh', display:'flex', flexDirection:'column'}}>
                    <MSliderBox src={window.location.origin + '/media/menu/wines.png'} />
                   
                                    <div style={{display:'flex', justifyContent:'space-between', marginTop:'1vh'}} className='bold'>
                                        <TextInfo>{t('card.wines')}</TextInfo>
                                        {/* <img src={window.location.origin + '/media/icons/menu_arrow.png'} height='20vh'/> */}
                                    </div>
                 
                </div>
            </Link>
            </SwiperSlide>

            </Swiper>
        </div>
    )
}

export default MenuSlider
